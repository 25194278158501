.navbg {
  background-image: url(./assets//images/navbg.svg);
  background-size: cover;
}
.greennavbg {
  background-image: url(./assets//images/greenavbg.svg);
  background-size: cover;
}

.navigations li {
  border-bottom: 2px solid transparent;
}
.navigations li:hover {
  border-bottom: 2px solid white;
  transition-property: border-bottom;
  transition-duration: 2s;
}
.mobile-naviagtions li {
  border-bottom: 2px solid transparent;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.mobile-naviagtions li:hover {
  border-bottom: 2px solid white;
}
.headerbg {
  background-image: url(./assets/images/headerbg.svg);
  background-color: black;
  background-size: cover;
  width: 100%;
  /* height: 500px; */
}
.headerbg input::placeholder {
  text-align: center;
}

.aboutImage {
  background-image: url(./assets/images/about_hero_img.png);
  background-color: black;
  background-size: cover;
  width: 100%;
}

.brokerheroimg {
  background-image: url(./assets/images/brokerheroimg.svg);
  background-color: black;
  background-size: cover;
  width: 100%;
}

.inputimgclass {
  /* background-image: url(./assets/images/inputimg.svg); */
  /* background-color: #0E9F6E; */
  /* opacity: 0.2; */
  background-size: cover;
  width: 100%;
  color: white;
  border: 1px solid gray;
}

.auth_bg {
  background-image: url(./assets/images/backgroundimg.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.news_header {
  background-image: url(./assets/images/newsflowbg.svg);
  background-color: green;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}



@media screen and (max-width: 768px) {
  .headerbg {
    height: 500px;
    padding: 0px 10px 0px 10px;
  }
  .aboutImage {
    background-image: url(./assets/images/about_hero_img.png);
    background-color: black;
    background-size: contain;
    width: 100%;
  }
}